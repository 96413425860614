import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ConnectChannelsApi} from '../../../api/connect-channel-api/connect-channels-api';
import {IQueryParams, ISortDto, SortDirection, SortField} from '../../../api/DTOs/IFilterDtos';
import {IConnectChannelRequestDto} from '../../../api/connect-channel-api/IConnectChannelRequestDto';
import {IPaginationInfo} from '../../../api/Paginator';
import {ErrorStub} from '../../../components/error-stub';
import {ConnectChannelRequestsTable} from './connect-channel-requests-table';
import {FilterRequestStatus} from '../../../api/connect-channel-api/FilterRequestStatus';
import {ApiRequestException} from '../../../api/axios-instance';
import {useLoading} from '../../../hooks/use-loading';
import {Filter, FilterType} from '../../../components/filters/filters';
import {FilterBuilder} from '../../../components/filters/filter-builder';
import {BaseListPage} from '../../base/base-list-page';
import {useSelectApi} from '../../../hooks/use-select-api';

const defaultSortOptions: ISortDto = {
  field: SortField.CREATED_AT,
  direction: SortDirection.DESC,
};

export const ConnectChannelRequestsPage: React.FC<any> = () => {
  const intl = useIntl();

  const {fetchSelectValuesByKey, selectsOptions, optionsLoadings} = useSelectApi();
  const api = new ConnectChannelsApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
  });

  const [connectChannelRequests, setConnectChannelRequests] = useState<Array<IConnectChannelRequestDto>>([]);
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo | null>(null);

  const sortConfig = useMemo<Array<Filter>>(() => {
    return FilterBuilder.buildSort({
      name: 'channels_requests_sort',
      defaultOptions: defaultSortOptions,
      options: [
        {
          label: `${intl.formatMessage({id: 'NEW_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.DESC},
        },
        {
          label: `${intl.formatMessage({id: 'OLD_FIRST'})}`,
          value: {field: SortField.CREATED_AT, direction: SortDirection.ASC},
        },
      ],
    });
  }, []);
  const filterConfig = useMemo<Array<Filter>>((): Array<Filter> => {
    return FilterBuilder.buildFilter({
      loadingSelect: optionsLoadings,
      allSelectValues: selectsOptions,
      filters: [
        {
          type: FilterType.SELECT,
          name: 'user_id',
          selectKey: 'users',
          placeholder: intl.formatMessage({id: 'USERS'}),
        },
        {
          type: FilterType.SELECT,
          name: 'request_status',
          options: [FilterRequestStatus.IN_WORK, FilterRequestStatus.APPROVED, FilterRequestStatus.REJECTED].map(s => ({
            value: s,
            label: intl.formatMessage({id: 'FILTER_REQUEST_STATUS_' + s}),
          })),
          placeholder: intl.formatMessage({id: 'STATUS'}),
        },
      ],
    });
  }, [selectsOptions, optionsLoadings]);

  useEffect(() => {
    Promise.all([fetchSelectValuesByKey('users')]).then();
  }, []);

  const fetchConnectChannelRequests = async (filter?: IQueryParams) => {
    try {
      setError(null);
      startLoading('page');
      const result = await api.getUsersConnectChannelRequests(filter);
      setConnectChannelRequests(result.data.items);
      setPaginationInfo(result.data.paginator);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };

  const renderChannelRequests = () => {
    if (connectChannelRequests.length === 0) {
      return (
        <div className={'text-center'}>
          <FormattedMessage id={'NOT_FOUND'} />
        </div>
      );
    }

    return <ConnectChannelRequestsTable connectRequests={connectChannelRequests} />;
  };

  if (error) {
    return <ErrorStub error={error} />;
  }

  return (
    <BaseListPage
      loading={loadings.page}
      filtersConfig={filterConfig}
      sortsConfig={sortConfig}
      pagination={{info: paginationInfo}}
      fetchData={fetchConnectChannelRequests}>
      {renderChannelRequests()}
    </BaseListPage>
  );
};
