import {
  FinancialReportSource,
  FinancialReportType,
  ReportsProcessingStatus,
} from '../../app/api/financial-report-api/FinancialBulkReports';
import {
  AvailableContractChange,
  ContractStatus,
  ContractType,
  ReportFrequency,
} from '../../app/api/contract-api/Contract';
import {PaymentRequestDestination, PaymentRequestStatus} from '../../app/api/DTOs/PaymentRequest';
import {FilterRequestStatus} from '../../app/api/connect-channel-api/FilterRequestStatus';
import {Currency, TransactionStatus} from '../../app/api/DTOs/Transaction';
import {SupportTicketStatus, SupportTicketType} from '../../app/api/support-tickets-api/ISupportTicketDto';
import {AdsStatus} from '../../app/api/advertisement-api/IAdvertisementRequestDto';
import {ArticleStatus} from '../../app/api/knowledge-base-api/IArticleDto';
import {VideoActionType, VideoStatus} from '../../app/api/DTOs/Video';
import {ContractorDataValidationStatus, ContractorType} from '../../app/api/contractor-api/Contractor';
import {DateFormat} from '../../app/components/Inputs/InputDatepicker';
import {Feed} from '../../app/api/DTOs/Feed';
import {TransactionAction} from '../../app/pages/admin/transactions/transaction-action-types';
import {TransactionOperationType} from '../../app/api/DTOs/TransactionOperation';
import {ReportPeriodStatus} from '../../app/api/DTOs/ReportPeriod';
import {FinancialAccountType} from '../../app/api/DTOs/FinancialAccount';
import {ContractorDocumentType} from '../../app/api/DTOs/ContractorDocument';
import {McPayAuthStateStatus, McPayTransactionStateType} from '../../app/hooks/apis/useMcPayStatesApi';

export default {
  'BUTTONS.SUBMIT': 'Отправить',
  'BUTTONS.CANCEL': 'Отменить',
  'AUTH.GENERAL.WELCOME': 'Добро пожаловать в StarPro',
  'AUTH.GENERAL.WELCOME_SUBTITLE': 'Развитая экосистема для создателей контента',
  'AUTH.GENERAL.NO_ACCOUNT': 'Нет аккаунта?',
  'AUTH.GENERAL.ALREADY_HAVE_ACCOUNT': 'Уже есть аккаунт?',
  'AUTH.GENERAL.SIGNUP_BUTTON': 'Зарегистрироваться',
  'AUTH.GENERAL.FORGOT_BUTTON': 'Забыли пароль',
  'AUTH.GENERAL.BACK_BUTTON': 'Назад',
  'AUTH.LOGIN.TITLE': 'Войти в аккаунт',
  'AUTH.LOGIN.SUB_TITLE': 'Введите электронную почту и пароль',
  'AUTH.LOGIN.BUTTON': 'Войти',
  'AUTH.FORGOT.TITLE': 'Забыли пароль?',
  'AUTH.FORGOT.DESC': 'Введите email, чтобы сбросить пароль',
  'AUTH.REGISTER.TITLE': 'Регистрация',
  'AUTH.REGISTER.DESC': 'Для создания аккаунта заполните форму',
  'AUTH.REGISTER.AGREEMENT': 'Я соглашаюсь с Политикой конфиденциальности при регистрации и создании личного кабинета.',
  'AUTH.REGISTER.UNEXPECTED_ERROR': 'Что-то пошло не так, попробуйте позже',
  'AUTH.INPUT.EMAIL': 'Электронная почта',
  'AUTH.INPUT.PASSWORD': 'Пароль',
  'AUTH.INPUT.CONFIRM_PASSWORD': 'Повторите пароль',

  ID: 'ID',
  NAME: 'Имя',
  USER: 'Пользователь',
  ADMIN: 'Администратор',
  USER_PROFILE: 'Профиль',
  LOGIN_ERROR: 'Некорректная почта или пароль',

  SUBMIT: 'Отправить',
  CANCEL: 'Отменить',
  NOT_FOUND: 'Список пуст',
  DELETE: 'Удалить',
  BLOCK: 'Заблокировать',
  UNBLOCK: 'Разблокировать',
  DOWNLOAD: 'Скачать',
  TRY_AGAIN: 'Повторить обработку',
  TRY_DOWNLOAD_AGAIN: 'Загрузить снова',
  CREATE_CONTRACT: 'Создать договор',
  CLOSE: 'Закрыть',
  EDIT: 'Редактировать',
  CREATE: 'Создать',
  CLEAR: 'Очистить',

  YOUTUBE_CHANNELS: 'YouTube каналы',
  YOUTUBE_CHANNEL: 'YouTube канал',
  YOUTUBE_ALL_VIDEOS: 'YouTube видео',
  USER_MANAGEMENT: 'Пользователи',
  TRANSACTIONS: 'Транзакции',
  TRANSACTION: 'Транзакция',
  FINANCIAL_OPERATIONS: 'Финансовые операции',
  FINANCIAL_OPERATION: 'Финансовая операция',
  KNOWLEDGE_BASE: 'База знаний',
  ACCOUNT: 'Счёт',
  PAYOUTS: 'Выплаты',
  REVENUES: 'Доходы',
  CONTRACTS: 'Договоры',
  CONTRACT: 'Договор',

  CARDS: 'Карточки',
  TABLE: 'Таблица',

  SORT_FIELD: 'Сортировка',
  CHANNELS: 'Каналы',

  TITLE: 'Название',
  LIKES: 'Лайки',
  DISLIKES: 'Дизлайки',
  VIEWS: 'Просмотры',
  DYNAMICS: 'Динамика',
  COMMENTS: 'Комментарии',
  SUBSCRIBERS: 'Подписчики',
  NEW_FIRST: 'Сначала новые',
  OLD_FIRST: 'Сначала старые',
  UPDATED_FIRST: 'Сначала обновлённые',
  UPDATED_LAST: 'Обновлённые в конце',
  INNER_FIRST: 'Сначала внутренние счета',
  CACHE_FIRST: 'Сначала денежные счета',

  ASC: 'по возврастанию',
  DESC: 'по убыванию',

  VIDEOS: 'Видео',
  METRICS: 'Метрики',

  LAST7DAYS: 'Последние 7 дней',
  LAST14DAYS: 'Последние 14 дней',
  LAST28DAYS: 'Последние 28 дней',
  LAST90DAYS: 'Последние 90 дней',

  WEEK: 'Неделя',
  QUARTER: 'Квартал',
  MONTH: 'Месяц',
  YEAR: 'Год',

  SUPPORT: 'Поддержка',
  LOGOUT: 'Выйти',

  RESET_PASSWORD: 'Сбросить пароль',
  SUCCESS_SENT_RESET_PASSWORD_EMAIL:
    'На указанный Вами адрес эл.почты отправлено письмо с инструкциями для восстановления пароля',
  SUCCESS_SEND_GENERATE_REPORT_REQUEST: 'В ближайшее время отчёт будет сгенерирован и отправлен на Вашу эл.почту',
  NOT_FOUND_THIS_ACCOUNT: 'Не удалось найти данный аккаунт',
  UNEXPECTED_ERROR: 'Что-то пошло не так, попробуйте позже',

  CREATE_NEW_PASSWORD: 'Создайте новый пароль',
  PROFILE: 'Профиль',
  CHANGE_PASSWORD: 'Сменить пароль',
  SUCCESS_CHANGE_PASSWORD: 'Пароль успешно изменён',
  NEW_PASSWORD: 'Новый пароль',
  CONFIRM_NEW_PASSWORD: 'Подтвердите новый пароль',
  CURRENT_PASSWORD: 'Текущий пароль',

  CONNECT: 'Подключиться',
  CONNECTED: 'Подключена',
  INTEGRATE_WITH_YOUTUBE: 'Интеграция с YouTube',
  INTEGRATE_WITH_YOUTUBE_DESCRIPTION:
    'Предоставьте доступ к google-аккаунту для того, чтобы активировать основную функциональность',
  SUCCESS_COMPLETE_YOUTUBE_INTEGRATION: 'Интеграция прошла успешно',
  ERROR_COMPLETE_YOUTUBE_INTEGRATION: 'Не удалось выполнить интеграцию',

  SUCCESS_UPLOAD_REPORTS: 'Выгрузка отчётов успешно завершена',
  ERROR_UPLOAD_REPORTS: 'Во время выгрузки отчётов произошла ошибка',

  ADMINISTRATE: 'Администрирование',
  YOUTUBE_INTEGRATION: 'Интеграция с YouTube',
  DASHBOARD: 'Панель управления',

  EXPIRED: 'Истекает',
  TOKEN_TYPE: 'Тип токена',
  CONTRACT_IS_EXPIRED: 'Срок действия договора истек',

  FINANCIAL_REPORTS: 'Финансовые отчёты',
  FINANCIAL_REPORT: 'Финансовый отчёт',
  REPORTS_UPLOAD: 'Загрузить',
  YOUTUBE_FINANCIAL_REPORTS: 'Импорт финансовых отчётов',
  REPORT_PERIOD: 'Период загрузки',
  LABEL_PERIOD: 'Выберите период загрузки',
  DROP_FILES: 'Для загрузки переместите файл сюда, либо нажмите',
  GO: 'Перейти',
  GO_BACK: 'Вернуться назад',

  FILE_NAME: 'Имя файла',
  SIZE: 'Размер',
  REPORT_TYPE: 'Тип',
  CREATED_AT: 'Создано',
  UPDATED_AT: 'Обновлено',
  BLOCKED_AT: 'Заблокировано',
  USER_TYPE: 'Тип',
  EMAIL: 'Email',
  STATUS: 'Статус',
  ACTIONS: 'Действия',
  ACTION: 'Действие',
  EVENT: 'Событие',
  GET_USERS_ERROR: 'Неожиданная ошибка при получении пользователей',
  CONFIRM_DELETE_USER: 'Вы уверены, что хотите удалить пользователя?',
  CONFIRM_BLOCK_USER: 'Вы уверены, что хотите заблокировать пользователя?',
  CONFIRM_UNBLOCK_USER: 'Вы уверены, что хотите разблокировать пользователя?',
  CONFIRM_RESET_PASSWORD_USER: 'Вы уверены, что хотите сбросить пароль пользователя?',
  SUCCESS_COMPLETE_ACTION_WITH_USER: 'Операция успешно завершена',
  ERROR_COMPLETE_ACTION_WITH_USER: 'Не удалось выполнить операцию',

  CONTRACTOR: 'Контрагент',
  CONTRACTORS: 'Контрагенты',
  USERS: 'Пользователи',
  ACCOUNTS: 'Счета',
  SUM: 'Сумма',
  FROM: 'От кого',
  TO: 'Куда',

  TOGGLE_MENU: 'Показать/Скрыть меню',

  CURRENT_BALANCE: 'Текущий баланс',
  DESCRIPTION: 'Описание',
  ORIGINAL_DESCRIPTION: 'Оригинальное описание',
  FINANCIAL_ACCOUNTS: 'Финансовые счета',
  BALANCE: 'Баланс',
  ACCOUNT_TYPE: 'Тип счёта',
  INNER_ACCOUNT: 'Внутренний счёт',
  CACHE_ACCOUNT: 'Денежный счёт',
  CONTRACT_NUMBER: 'Номер',
  VALIDITY_PERIOD: 'Срок действия',
  CONTRACT_START_DATE: 'Начало действия',
  CONTRACT_END_DATE: 'Окончание действия',
  CONTRACT_EXPIRES_AT: 'Дата окончания договора',
  CONTRACT_NUMBER_BE_AUTOGENERATED: 'Номер договора будет сгенерирован автоматически',
  CHANNEL_NAME: 'Название канала',
  CHANNEL: 'Канал',
  SUP_AGREEMENT_NUMBER: 'Номер',
  ADDENDUM_LIST: 'Список приложений',
  RATE_PERCENT: 'Процентная ставка',
  CREATE_ADDENDUM: 'Создать приложение',
  ADDENDUM: 'Приложение',
  SOURCE: 'Источник',
  CONTRACTOR_EMAIL: 'Email контрагента',
  CONTRACTOR_NAME: 'Имя контрагента',
  USER_NAME: 'Имя пользователя',
  EDIT_CONTRACT: 'Редактировать договор',
  EDIT_CONTRACTOR: 'Редактировать контрагента',
  CREATE_USER: 'Создать пользователя',
  EDIT_USER: 'Редактировать пользователя',
  PASSWORD: 'Пароль',
  PASSWORD_CONFIRMATION: 'Подтвердите пароль',
  ADMIN_PRIVILEGES: 'Привелегии администратора',
  USERS_ACTIONS: 'Действия пользователей',
  STATUS_CODE: 'Статус код',
  SHOW_MORE: 'Показать больше',
  AGENT: 'Агент',
  TIME: 'Время',
  TYPE: 'Тип',
  REPORT_FREQUENCY: 'Периодичность отчетов',
  PAYMENT_REQUEST: 'Запросить выплату',
  PAYMENT_REQUESTS: 'Запросы на выплату',
  CONNECT_CHANNELS_REQUESTS: "Запросы на подключение",
  SUCCESS_PAYMENT_REQUEST: 'Запрос на выплату успешно создан',
  SEND: 'Отправить',
  PERFORM: 'Выполнить',
  REJECT: 'Отклонить',
  REJECT_REASON: 'Причина отказа',
  DESCRIBE_REJECT_REASON: 'Опишите причину отказа',
  DESCRIBE_CANCEL_REASON: 'Опишите причину отмены',
  CANCEL_REASON: 'Причина отмены',
  OPERATION_WAS_CANCELED: 'Данная операция была отменена',
  ADD_YOUTUBE_CHANNEL: 'Добавление YouTube-канала',
  ADD_YOUTUBE_CHANNEL_DESCRIPTION:
    'Предоставьте доступ сервисам StarPro, чтобы помочь вашему YouTube-каналу развиваться.',
  ADD: 'Добавить',
  ADD_CHANNEL_REQUESTS: 'Запросы на подключение YouTube-каналов',
  PUBLISHED_AT: 'Опубликовано',
  PUBLISH: 'Опубликовать',
  APPROVE: 'Подтвердить',
  PERIOD: 'Период',
  ACCOUNT_OPERATIONS: 'Финансовые операции',
  PAYMENT_DOCUMENTS: 'Документы по операции',

  MIN_PAYOUT_AMOUNT_IS: 'Минимальная сумма для выплаты составляет',
  OF: 'из',
  DRAW_SIGNATURE: 'Распишитесь',
  SIGN: 'Расписаться',
  SIGNATURE: 'Подпись',
  REQUISITES: 'Реквизиты',
  REPORT: 'Отчёт',
  PAYMENT_REQUISITES: 'Платёжные реквизиты',
  SWIFT_NUMBER: 'SWIFT/BIC/Routing',
  ACCOUNT_NUMBER: 'Номер счёта',
  MONTHS: 'Месяцы ',
  GENERATE_REPORT: 'Генерация финансового отчёта',
  GENERATE: 'Сгенерировать',
  EDIT_PAYMENT_REQUISITES: 'Редактировать платёжные реквизиты',
  ADDRESS: 'Адрес',
  SHOW_REQUESTS: 'Показать мои запросы',
  SUCCESS_SENT_CONNECT_CHANNEL_REQUEST:
    'Запрос на подключение канала успешно создан. В ближайшее время он будет обработан администратором.',
  ERROR_SENT_CONNECT_CHANNEL_REQUEST:
    'Подключение канала не удалось. Убедитесь, что данный канал не был подключен ранее, либо попробуйте снова.',
  NOTICE_EDIT_RESTRICTED:
    'Редактирование некоторых полей заблокировано, т.к по данному приложению существуют транзакции.',
  EDIT_ADDENDUM: 'Редактировать приложение',
  TRANSACTION_SUM_PREVIOUS_MONTH: 'Сумма транзакций за предыдущий месяц',
  TRANSACTION_SUM_CURRENT_MONTH: 'Сумма транзакций за текущий месяц',
  NOTICE_ACCRUALS_IN_USD: 'Для начисления дохода требуется указать USD счет',
  UNCONFIRMED_BALANCE: 'Неподтверждённый баланс',
  WITHDRAWAL_AVAILABLE_BALANCE: 'Доступный для списания баланс',
  CREDIT_BALANCE: 'Кредитный баланс',
  DOWNLOAD_XLSX_REPORT: 'Выгрузить список транзакций в XLSX',
  CHANNEL_TRANSACTIONS_INCOME_REPORT: 'Сформировать отчет по доходам каналов',
  GENERATE_INCOME_REPORT: 'Выгрузить отчёт по доходам каналов',
  REQUESTS: 'Запросы',
  CREATE_TRANSACTION: 'Создать транзакцию',
  CONFIRM_ALL_TRANSACTIONS: 'Подтвердить все транзакции',
  CREATE_ACTION_TRANSACTION: 'Создать транзакцию на вывод',
  CREATE_ACTION_DEBIT_TRANSACTION: 'Создать транзакцию на списание',
  CREATE_ACTION_TAX_TRANSACTION: 'Создать транзакцию на списание налога',
  CONFIRM_TRANSACTION: 'Вы уверены, что хотите выполнить подтверждение транзакции?',
  CONFIRM_TRANSACTION_DESCRIPTION: 'Пользователь получит уведомление о начислении на его счет.',
  INSTRUCTIONS: 'Инструкции',
  ADVERTISEMENT: 'Размещение рекламы',
  CONFIRM_TRANSACTION_SUCCESS: 'Транзакция успешно выполнена',
  CANCEL_TRANSACTION_SUCCESS: 'Транзакция успешно отменена',
  REJECT_TRANSACTION_SUCCESS: 'Транзакция успешно отклонена',
  SAVE: 'Сохранить',

  MAILINGS: 'Рассылки',
  COMPLETE_REGISTRATION: 'Завершение регистрации',
  DASHBOARD_WITHOUT_ACTIVE_CONTRACT: 'Без активного договора',
  DASHBOARD_VIDEOS_COUNT: 'Кол-во видео',
  DASHBOARD_CHANNELS_COUNT: 'Кол-во каналов',
  DASHBOARD_CHANNEL_CONNECT_REQUESTS: 'Кол-во запросов на подключение канала',
  DASHBOARD_HAS_SIGNATURE: 'Подпись указана',
  DASHBOARD_HASNT_SIGNATURE: 'Подпись не указана',
  DASHBOARD_HAS_PAYMENT_REQUISITES: 'Платежные реквизиты указаны',
  DASHBOARD_HASNT_PAYMENT_REQUISITES: 'Платежные реквизиты не указаны',
  DASHBOARD_USERS_COUNT: 'Кол-во пользователей',
  DASHBOARD_TODAY_COUNT: 'За сегодня',
  DASHBOARD_IN_WORK_COUNT: 'В работе',
  DASHBOARD_PAYMENT_REQUEST_IN_WORK_SUM: 'Текущая сумма запросов',
  DASHBOARD_CHANNELS_WITHOUT_ADDENDUM: 'Каналы не связанные с приложением',
  DASHBOARD_ERRORS_COUNT: 'Кол-во ошибок за сегодня',
  DASHBOARD_TRANSACTION_WAITING_CONFIRM_COUNT: 'Кол-во транзакций ожидающих подтверждения',
  DASHBOARD_TRANSACTION_MANAGEMENT: 'Управление транзакциями',
  DASHBOARD_PAYMENTS_MANAGEMENT: 'Управление выплатами',
  DASHBOARD_USERS_CURRENT_MONTH_COUNT: 'В текущем месяце',
  OOPS: 'Упс',
  SOMETHING_WENT_WRONG: 'Что-то пошло не так',
  PAGE_NOT_FOUND: 'Страница не найдена',
  EMPTY_REPORT_CANNOT_CREATE_FINANCIAL_REPORT: 'Из-за отсутствия договоров выгрузка финансового отчёта невозможна',
  TOTAL_TRANSACTIONS_SUM: 'Всего транзакций на сумму',
  SUBJECT: 'Тема',
  CHOOSE_USERS: 'Выберите пользователей',
  CHOOSE_ALL: 'Выбрать всё',
  CREATE_MAILING: 'Создать рассылку',
  MULTILINGUAL: 'Мультиязычность',
  MARKDOWN: 'Markdown',
  HTML: 'HTML',
  PREVIEW: 'Предпросмотр',
  SUCCESS_SEND_MAILING: 'Рассылка успешно осуществлена',
  EDIT_MAILING: 'Редактировать рассылку',
  CONFIRM_DELETE_MAILING: 'Вы уверены, что хотите удалить рассылку?',
  EMPTY: 'Пусто',

  HAS_TAX_ON_PROFITS: 'Отметьте, если вы работаете с НДС',
  INTEGRATION_ENABLE: 'Интеграция',
  INTEGRATION_PRICE: 'Стоимость интеграции',
  ADVANCED_INTEGRATION_ENABLE: 'Расширенная интеграция',
  ADVANCED_INTEGRATION_PRICE: 'Стоимость расширенной интеграции',
  POST_ENABLE: 'Разрешение на размещение',
  POST_PRICE: 'Стоимость размещения',
  PRE_ROLL_ENABLE: 'Пре-ролл',
  PRE_ROLL_PRICE: 'Стоимость пре-ролла',
  PRODUCT_PLACEMENT_ENABLE: 'Продакт-плейсмент',
  PRODUCT_PLACEMENT_PRICE: 'Стоимость продакт-плейсмента',
  SPECIAL_PROJECT_ENABLE: 'Специальный проект',
  SPECIAL_PROJECT_PRICE: 'Стоимость специального проекта',
  EXPECTED_VIEWS_COUNT: 'Ожидаемое кол-во просмотров',
  TAX: 'Налог на доходы',
  RELEASE_DATE: 'Планируемая дата',
  COMMENT: 'Комментарий',
  CHOSEN: 'Выбрано',
  CONFIRM_DELETE_ADS_REQUEST: 'Вы уверены, что хотите удалить запрос на размещение рекламы?',
  ADVERTISEMENT_REQUESTS: 'Запросы на размещение рекламы',
  SUCCESS_ADS_REQUEST_REJECT: 'Запрос на размещение рекламы успешно отклонён',
  SUCCESS_ADS_REQUEST_CONFIRM: 'Запрос на размещение рекламы успешно подтверждён',
  PRICE: 'Стоимость',
  PRICE_WITHOUT_TAX: 'Стоимость без налога',
  CREATE_ADS_REQUEST: 'Создать запрос на размещение рекламы',
  ADS_REQUEST_COMMENT_PLACEHOLDER:
    'Расскажите подробнее о будущем контенте:\n\nПланируемая тематика ролика, Приглашённые гости\nОжидаемое кол-во просмотров, Примечания',
  ADS_REQUESTS_NOT_FOUND: 'Найдем рекламодателей для вашего контента!',
  CONFIRM_APPROVE_ADS_REQUEST: 'Вы уверены, что хотите подтвердить запрос на рекламу?',
  CREATE_ADS_REQUEST_MARKETING_DESCRIPTION:
    'Этот раздел предназначен для того, чтобы сообщать нам о свободных рекламных слотах.\n\n' +
    'Вместе c <playersteamlink>Players</playersteamlink> и другими партнерами мы поможем в поиске спонсоров \n' +
    'на грядущий контент, а так же разместим информацию в <playerscontentbotlink>Players Content Bot.</playerscontentbotlink>',
  COOKIE_NOTICE: 'Для повышения удобства сайта мы используем файлы Cookie.',
  I_AGREE: 'Согласиться',

  CREATE_TICKET: 'Создать обращение',
  TICKET_STATUS: 'Статус обращений',
  OPEN_TICKETS: 'Открытые',
  CLOSED_TICKETS: 'Закрытые',
  TICKET_TOPIC: 'Тема обращения',
  TICKET_TEXT: 'Текст сообщения',
  TICKET_AUTHOR: 'Автор',
  OPEN_SUPPORT_TICKET: 'Открыть обращение',
  TICKETS_NOT_FOUND: 'У вас нет открытых обращений в поддержку',
  CREATE_TICKET_MARKETING_DESCRIPTION:
    'Если у вас есть вопрос, проблема или предложение\nСвяжитесь с нами, путём создания обращения.\nМы обязательно рассмотрим его.',
  SUPPORT_TICKET_REASON: 'Причина обращения',
  WRITE_MESSAGE: 'Напишите сообщение',
  MESSAGE_COUNT: 'Кол-во сообщений',
  TICKET_OPENED_AT: 'Открыто',
  CONFIRM_CLOSE_APPEAL: 'Вы уверены, что хотите закрыть обращение?',
  CLOSE_APPEAL: 'Закрыть обращение',
  APPEAL_WAS_CLOSED: 'Обращение было закрыто',
  UPDATE_CLIENT_NOTICE: 'Сайт был обновлён. Чтобы получить изменения, обновите страницу.',
  CONFIRM: 'Подтвердить',
  ATTENTION: 'Внимание',
  REFRESH: 'Обновить',
  REVOKE_ACCESS: 'Отозвать доступ к YouTube-аккаунту',
  REVOKE_ACCESS_DESCRIPTION:
    'Если вы хотите отозвать доступ к своему YouTube аккаунту, то можете сделать это на <permissonslink>странице управления доступом к Google-аккаунту</permissonslink>.',
  SHOW_ALL: 'Показать всё',
  HIDE: 'Скрыть',
  CHANNEL_HIDDEN_FROM_YOUTUBE:
    'Данный канал более не находится в многоканальной сети.\nДля того, чтобы выгрузить отчет о доходах пройдите в раздел <redirect>Финансовые отчеты.</redirect>',
  HIDDEN_CHANNELS_AND_VIDEO_FROM_YOUTUBE:
    'Данный канал не находится в многоканальной сети, также данное видео было скрыто.\nДля того, чтобы выгрузить отчет о доходах пройдите в раздел <redirect>Финансовые отчеты.</redirect>',
  VIDEO_HIDDEN_FROM_YOUTUBE:
    'Данное видео было скрыто.\nДля того, чтобы выгрузить отчет о доходах пройдите в раздел <redirect>Финансовые отчеты.</redirect>',
  NOTICE: 'Внимание',
  CHANNEL_NOT_AVAILABLE: 'Канал недоступен',
  RESET_PASSWORD_TOKEN_EXPIRED: 'Токен сброса пароля устарел. Пожалуйста, запросите восстановление пароля заново.',
  REGISTRATION_CODE_CONFIRMATION: 'Подтверждение регистрации',
  REGISTRATION_CODE_CONFIRMATION_DESC: 'На почту <email></email> отправлено письмо с кодом подтверждения',
  CODE_DIDNT_COME: 'Не пришёл код?',
  SEND_AGAIN: 'Отправить ещё раз',
  BACK_TO_REGISTER: 'Вернуться к регистрации',
  CONFIRMATION_CODE_IS_INVALID: 'Код подтверждения недействителен',
  CREDENTIALS_ARE_OUT_OF_DATE: 'Данные учётной записи устарели. Повторите регистрацию',
  ACCESSIBILITY: 'Доступность',
  CONTRACT_EXISTENCE: 'Наличие договора',
  HAS_CONTRACT: 'Есть договор',
  HAS_NO_CONTRACT: 'Нет договора',
  ACTIVE: 'Действующие',
  INACTIVE: 'Не действующие',
  ACTIVE_VIDEO: 'Доступные',
  INACTIVE_VIDEO: 'Скрытые',
  PERIOD_START_DATE: 'Период от',
  PERIOD_END_DATE: 'Период до',
  TAX_PERIOD: 'Налоговый период',
  CREATE_NEW_CHAPTER: 'Создать новый раздел',
  DRAFT: 'Черновик',
  SAVE_AS_DRAFT: 'Сохранить как черновик',
  EDIT_CHAPTER: 'Редактировать раздел',
  CREATE_CHAPTER: 'Создать раздел',
  CREATE_ARTICLE: 'Создать статью',
  CONFIRM_DELETE_ARTICLE: 'Вы уверены, что хотите удалить статью?',
  CONFIRM_DELETE_CHAPTER: 'Вы уверены, что хотите удалить раздел?',
  CONFIRM_DELETE_GENRE: 'Вы уверены, что хотите удалить жанр?',
  CONFIRM_DELETE_COUNTRY: 'Вы уверены, что хотите удалить страну?',
  KNOWLEDGE_BASE_DESCRIPTION:
    'Здесь находятся ответы на главные вопросы о Личном кабинете StarPro и ваших возможностях в экосистеме.\n' +
    'Если вы не нашли в списке нужный вопрос или вам не подошел ответ, обращайтесь напрямую в <supportlink>службу поддержки</supportlink>.\n' +
    'Мы всегда рады вам помочь!',
  GENRE: 'Жанр',
  GENRES: 'Жанры',
  COUNTRY: 'Страна',
  COUNTRIES: 'Страны',
  COUNTRY_CODE: 'Код страны',
  ARTISTS: 'Исполнители',
  ARTIST: 'Исполнитель',
  ARTIST_LOGO: 'Обложка исполнителя',
  CREATE_ARTIST: 'Создать исполнителя',
  EDIT_ARTIST: 'Редактировать исполнителя',
  CONFIRM_DELETE_ARTIST: 'Вы уверены, что хотите удалить артиста?',
  SPECIFY_HEIGHT: 'Укажите высоту (px)',
  SPECIFY_WIDTH:
    'Укажите ширину (по умолчанию задаётся в px, чтобы задать значение в процентах, в конце значения укажите "%")',
  LABELS: 'Лейблы',
  LABEL: 'Лейбл',
  INVITE_TOKEN_EXPIRED: 'Данное приглашение устарело, попросите вашего контрагента прислать его вновь',
  INVITE_USER: 'Пригласить пользователя',
  ALLOW_ACCESS_TO_FINANCE: 'Разрешить доступ к финансам',
  INVITE_WAS_SUCCESS_SENT: 'Приглашение было успешно отправлено',
  UPLOAD_VIDEO: 'Загрузить видео',
  UPLOAD: 'Загрузить',
  CONFIRM_DELETE_UPLOADED_VIDEO: 'Вы уверены, что хотите удалить загруженное видео? (данное действие необратимо)',
  VIDEO_FILE: 'Файл видео',
  PREVIEW_FILE: 'Файл превью',
  VIDEO_SUCCESSFUL_UPLOAD: 'Видео успешно загружено',
  VIDEO_FAILED_UPLOAD: 'Во время загрузки видео произошла ошибка',
  IMAGE_SUCCESSFUL_UPLOAD: 'Изображение успешно загружено',
  IMAGE_FAILED_UPLOAD: 'Во время загрузки изображения произошла ошибка',
  EXPLICIT: 'Содержит нецензурную лексику',
  CODE: 'Код',
  CONTRACTOR_CODE: 'Код контрагента',
  CONTRACTOR_CODE_HELP_TEXT: 'Данный код позволяет единолично идентифицировать контрагента',
  VIDEO_CODE_NOTE: 'Генерация происходит автоматически',
  VIDEO_COUNTRIES_NOTE: 'Укажите страны, в которых будет доступно видео после публикации',
  AGE_LIMIT: 'Ограничение по возрасту',
  DIRECTOR: 'Режиссёр',
  AUTHORS: 'Авторы',
  VIDEO_TITLE_NOTE: 'Без имени исполнителя и кавычек',
  VIDEO_DESCRIPTION_NOTE: 'Указанный текст будет отображаться в одноименном разделе при просмотре видео в Youtube',
  VIDEO_PREVIEW_NOTE:
    'Формат картинки - JPEG (*.jpg, *.jpeg), размер - не менее 1280px * 720px, соотношение сторон - 16:9',
  VIDEO_CONTENT_NOTE: 'Файл должен быть в одном из следующих форматов: MP4, MOV, WMV, MPEG, AVI',
  WAIT_CANCEL_UPLOAD_VIDEO_MESSAGE: 'Отменяется выгрузка видео, пожалуйста подождите',
  VIDEO_AGE_LIMIT_NOTICE:
    'Видео, предназначенные только для зрителей старше 18 лет, показываются не во всех разделах YouTube, а реклама в них может быть ограничена или отключена. <redirect>Подробнее...</redirect>',
  FEEDS_NOTICE:
    'Выберите площадки, для которых будет доступен загружаемый контент. После релиза контента вы сможете найти его на выбранных площадках',
  UPLOAD_WATERMARK_FILES: 'Загрузить файлы с водяным знаком',
  ARTIST_IMAGE_UPLOAD_NOTE: 'Формат картинки - JPEG (*.jpg, *.jpeg), соотношение сторон - 1:1',
  CONTRACTOR_EXTERNAL_ID: 'Код правообладателя',
  CONTRACTOR_EXTERNAL_ID_NOTE: 'Собственный идентификатор',
  CREATE_CONTRACTOR: 'Создать контрагента',
  FEED_LOGO: 'Изображение фида',
  CREATE_FEED: 'Создать фид',
  EDIT_FEED: 'Редактировать фид',
  FEED: 'Фид',
  FEEDS: 'Фиды',
  APPROVED_FEEDS: 'Одобренные площадки',
  READY_TO_PUBLISH_SUCCESS:
    'Запрос на подготовку к публикации успешно отправлен, в ближайшее время видео будет готово к публикации.',
  ORIGINAL: 'Оригинал',
  PREPARED: 'Обработанное',
  UPLOAD_FILES_WITH_WATERMARKS: 'Загрузить файлы с водяными знаками',
  ACTIVITY_HISTORY: 'История изменений',
  ACTIVITY_DATE: 'Дата изменений',
  OTHER_ARTISTS_VIDEOS: 'Другие видео артистов',
  IMAGE_RATIOS_INCORRECT: 'Соотношение сторон изображения не соответсвует требованиям',
  REJECT_PUBLISH: 'Отклонить публикацию',
  CONFIRM_PUBLISH: 'Подтвердить публикацию',
  REJECT_PUBLISH_SUCCESS: 'Запрос на публикацию успешно отклонён',
  CONFIRM_PUBLISH_SUCCESS: 'Запрос на публикацию успешно подтверждён',
  CONFIRM_VIDEO_PUBLICATION: 'Вы уверены, что хотите подтвердить публикацию данного видео?',
  SYSTEM: 'Система',
  FILES: 'Файлы',
  CONFIRM_DELETE_FILE: 'Вы уверены, что хотите удалить файл?',
  FILE_SUCCESS_DELETED: 'Файл успешно удалён',
  FILE_FAILED_DELETED: 'Не удалось удалить файл',
  BUCKET: 'Раздел',
  STORAGE_CAPACITY: 'Использование хранилища',
  FILES_SIZES: 'Общий размер файлов',
  FILES_COUNT: 'Кол-во файлов',
  RELATION: 'Связь',
  RELATIONS: 'Связи',
  FIRST_NAME: 'Имя',
  LAST_NAME: 'Фамилия',
  MIDDLE_NAME: 'Отчество',
  PHONE_NUMBER: 'Номер телефона',
  CONTRACTOR_TYPE: 'Тип контрагента',
  YOU_REGISTERED_AS: 'Вы регистрируетесь как',
  DOLLAR: 'Доллар',
  RUBLE: 'Рубль',
  CHOOSE_CURRENCY: 'Выберите валюту',
  DATE_OF_BIRTH: 'Дата рождения',
  TAX_PAYER_NUMBER: 'ИНН',
  TAX_PAYER_KPP: 'КПП',
  VAT: 'НДС, %',
  VAT_PAYER: 'Плательщик НДС',
  SNILS: 'СНИЛС',
  SELF_EMPLOYED: 'Самозанятый',

  INFO_ABOUT_LEGAL: 'Данные об юридическом лице',
  INFO_ABOUT_INDIVIDUAL: 'Данные о физическом лице',
  INFO_ABOUT_LEGAL_SIGNATORY: 'Информация о подписанте',
  INFO_ABOUT_ENTREPRENEUR: 'Данные об индивидуальном предпринимателе',
  LEGAL_NAME: 'Наименование организации',
  LEGAL_OGRN: 'ОГРН',
  LEGAL_ADDRESS: 'Юридический адрес организации',
  OGRNIP: 'ОГРНИП',
  LEGAL_SIGNATORY_POSITION: 'Должность подписанта',
  LEGAL_SIGNATORY_ACTS_BY: 'Действует на основании',
  TRANSLIT: 'Транслитом',

  INFO_ABOUT_PASSPORT: 'Паспортные данные',
  PASSPORT_DIVISION_CODE: 'Код подразделения',
  PASSPORT_ISSUED_BY: 'Паспорт, кем выдан',
  PASSPORT_SERIES: 'Паспорт, серия',
  PASSPORT_NUMBER: 'Паспорт: серия, номер',
  PASSPORT_ISSUED_DATE: 'Паспорт, Дата выдачи',
  PASSPORT_REGISTRATION_ADDRESS: 'Адрес регистрации',

  INFO_ABOUT_ORGANIZATION: 'Информация об организации',
  INFO_ABOUT_BANK: 'Банковские данные',
  BANK_NAME: 'Наименование банка',
  BANK_ACCOUNT_NUMBER: 'Номер расчетного счёта',
  BANK_CORRESPONDENT_ACCOUNT_NUMBER: 'Корреспондентский счёт',
  BANK_INN: 'ИНН Банка',
  BANK_NUMBER: 'Банковский идентификационный код (БИК)',
  BANK_ADDRESS: 'Адрес банка',

  BENEFICIARY_NAME: 'Имя бенефициара',
  BENEFICIARY_ACCOUNT_NUMBER: 'Номер банковского счета бенефициара',
  BENEFICIARY_TRANSIT_ACCOUNT_NUMBER: 'Номер транзитного счета бенефициара',

  INTERMEDIARY_NAME: 'Наименование посредника',
  INTERMEDIARY_SWIFT: 'SWIFT/BIC/Routing посредника',
  INTERMEDIARY_ACCOUNT_NUMBER: 'Номер банковского счета посредника',

  CONTRACTOR_DATA_VALIDATION_STATUS: 'Статус проверки реквизитов',
  REGISTRATION_WELCOME_NOTICE:
    'Добро пожаловать в Личный Кабинет системы Star Pro!\nДля того, чтобы продолжить использование, вам потребуется заполнить данные о себе и подождать, пока администратор проверит ваши данные',
  REGISTRATION_FIX_REQUIREMENTS_NOTICE:
    'Администратор проверил ваши данные, для того, чтобы продолжить использование, вам потребуется исправить следующие замечания',
  REGISTRATION_WAITING_MODERATION_NOTICE: 'Ваши данные отправлены на проверку',
  REGISTRATION_WAITING_MODERATION_SUB_NOTICE:
    'Для того, чтобы у вас была возможность полноценно использовать Личный Кабинет, нам потребуется проверить ваши данные.\n' +
    'Данная процедура может занять продолжительное время. После проверки вам придёт уведомление на почту.',
  REGISTRATION_REJECTED_NOTICE: 'Администратор проверил данные и отказал в подтверждении',
  REGISTRATION_CONFIRMED_NOTICE: 'Администратор проверил и утвердил заполненные данные',
  REGISTRATION_REJECTED_SUB_NOTICE: 'После проверки ваших данных, администратор заблокировал возможность регистрации',
  WIZARD_STEP_FILL_FIELDS: 'Заполнение полей',
  WIZARD_STEP_WAITING_ADMINISTRATOR_CONFIRM: 'Проверка данных',
  WIZARD_STEP_SUCCESS: 'Завершено',
  WIZARD_NEXT: 'Далее',
  CONFIRM_APPROVE_REGISTRATION: 'Вы уверены, что хотите подтвердить данные контрагента?',
  APPROVE_REGISTRATION_SUCCESS: 'Подтверждение данных контрагента успешно завершено',
  DESCRIBE_REQUIREMENTS_REASON: 'Опишите требования',
  REQUEST_FIX_REQUIREMENTS: 'Запросить выполнение требований',
  REQUIREMENTS: 'Требования',
  REJECT_REGISTRATION_SUCCESS: 'Данные контрагента успешно отклонены',
  REQUEST_FIX_REQUIREMENTS_REGISTRATION_SUCCESS: 'Контрагенту отправлено требование о выполнении требований',
  IS_OUR_COMPANY: 'Наша организация',
  ACCOUNT_CURRENCY: 'Валюта счёта',
  RIGHTS_EXPIRATION: 'Дата окончания прав',
  YOUTUBE_VIDEO_ID: 'Идентификатор видео в сети Youtube',
  YOUTUBE_VIDEO_ID_SUCCESS_UPDATED: 'Идентификатор видео в сети Youtube успешно обновлен',
  SET_YOUTUBE_VIDEO_ID: 'Установить идентификатор из Youtube',
  SET_YANDEX_ZEN_CHANNEL_ID: 'Установить идентификатор канала в сети Яндекс Дзен',
  YANDEX_ZEN_CHANNEL_ID: 'Идентификатор канала в сети Яндекс Дзен',
  YANDEX_ZEN_CHANNEL_ID_SUCCESS_UPDATED: 'Идентификатор канала в сети Яндекс Дзен успешно обновлен',
  CURRENCY: 'Валюта',
  IS_SIGNED: 'Подписано',
  IS_NOT_SIGNED: 'Не подписано',
  CONTRACT_UPDATE_STATE_ON_DATE: 'Получить состояние договора на дату начала действия доп.соглашения',

  SUP_AGREEMENTS: 'Дополнительные соглашения',
  SUP_AGREEMENT: 'Дополнительное соглашение',
  CREATE_SUP_AGREEMENT: 'Создать доп.соглашение',
  EDIT_SUP_AGREEMENT: 'Редактировать доп.соглашение',
  SUP_AGREEMENT_STARTED_AT: 'Дата начала действия',
  SUP_AGREEMENT_EXPIRES_AT: 'Дата окончания действия',
  SUP_AGREEMENT_CHANGE_CHANNELS: 'Изменить YouTube-каналы',
  SUP_AGREEMENT_CHANGE_VIDEOS: 'Изменить видео',
  SUP_AGREEMENT_CHANGE_EXPIRES_AT: 'Изменить дату окончания договора',
  SUP_AGREEMENT_CHANGE_VIDEOS_RATE_PERCENT: 'Изменить процентную ставку видео',
  SUP_AGREEMENT_CHANGE_FEEDS: 'Изменить площадки для размещения',
  SUP_AGREEMENT_CHANGES: 'Изменения',
  SUP_AGREEMENT_SIGNED_STATUS: 'Подпись',
  SUP_AGREEMENT_ACTUAL_VIDEOS: 'Изменения видео',
  SUP_AGREEMENT_ACTUAL_CHANNELS: 'Изменения каналов',
  SUP_AGREEMENT_ACTUAL_FEEDS: 'Изменения площадок',
  SUP_AGREEMENT_ACTUAL_EXPIRED_AT: 'Изменение даты окончания договора',
  SUP_AGREEMENT_ACTUAL_VIDEO_RATE_PERCENT: 'Изменение ставки видеороликов',
  DOWNLOAD_DOCX_CONTRACT_TEMPLATE: 'Скачать шаблон договора',
  DOWNLOAD_DOCX_SUP_AGREEMENT_TEMPLATE: 'Скачать шаблон доп.соглашения',
  DOWNLOADS: 'Загрузки',
  DOWNLOADING: 'Загрузка',
  SUCCESS_DOWNLOAD_FILE: 'Файл успешно загружен',
  MY_DOCUMENTS: 'Мои документы',
  MY_DOCUMENTS_MARKETING_DESCRIPTION:
    'В данном разделе вам предоставится возможность загрузить шаблоны договоров, и доп.соглашений,\nтакже здесь будут доступны сканы подписанных документов',

  APPLICATION_SETTINGS: 'Настройки системы',
  SUCCESS_UPDATE_SETTINGS: 'Настройки успешно обновлены',
  ADS_REQUEST_NOTICE_RECIPIENT: 'Получатель уведомлений о новых рекламных запросах',
  CONNECT_CHANNEL_REQUEST_NOTICE_RECIPIENT: 'Получатель уведомлений о запросах на подключение канала',
  CHANGE_PAYMENT_REQUISITES_NOTICE_RECIPIENT: 'Получатель уведомлений об изменениях платежных реквизитов пользователей',
  PAYMENT_REQUEST_NOTICE_RECIPIENT: 'Получатель уведомлений о запросах на выплату',
  EXPIRING_CONTRACT_NOTIFY_USER: 'Получатель уведомлений об истекающих договорах',
  SUPPORT_TICKET_NOTICE_RECIPIENT: 'Получатель уведомлений о новых обращениях в тех.поддержку',
  MC_PAY_INTEGRATION_NOTICE_RECIPIENT: 'Получатель уведомлений о взаимодействии с MC PAY',
  PUBLISH_VIDEOS_NOTICE_RECIPIENT: 'Получатель уведомлений о новых видео',
  NEED_SEND_TRANSACTION_EMAIL_NOTIFICATION: 'Уведомление пользователей о создании транзакций',
  TRANSACTION_OPERATIONS: 'Операции',
  TO_TRANSACTION: 'Перейти к транзакции',
  CREATED_AT_PERIOD: 'Период даты создания',
  MY_ORGANIZATION: 'Моя организация',
  SOME_ACTIONS_UNAVAILABLE: 'В данный момент некоторые действия недоступны',
  NEED_FILL_CONTRACTOR_FIELDS:
    'Чтобы воспользоваться всеми возможностями платформы, перейдите к заполнениню данных организации.',
  SUP_AGREEMENT_SUCCESS_DELETED: 'Доп.соглашение успешно удалено',
  REPORTING_PERIODS: 'Отчётные периоды',
  REPORTING_PERIOD_STARTED_AT: 'Дата начала',
  REPORTING_PERIOD_EXPIRES_AT: 'Дата окончания',
  CONFIRM_OPEN_REPORTING_PERIOD: 'Вы уверены, что хотите открыть отчётный период?',
  CONFIRM_CLOSE_REPORTING_PERIOD: 'Вы уверены, что хотите закрыть отчётный период?',
  OPEN: 'Открыть',
  USER_AGREEMENT: 'Пользовательское соглашение',
  OTHER: 'Прочее',
  FINANCIAL_OPERATIONS_NOT_FOUND_MARKETING_TITLE: 'Финансовые операции не найдены',
  FINANCIAL_OPERATIONS_NOT_FOUND_MARKETING_DESCRIPTION:
    'Когда у вас появятся первые доходы, они будут отображены здесь',
  USERS_NOT_FOUND_MARKETING_TITLE: 'Пользователи не найдены',
  USERS_NOT_FOUND_MARKETING_DESCRIPTION:
    'Отправляйте приглашения с призывами присоединяться к сети StarPro.\nЗарегистрированные пользователи будут отображаться здесь.',
  VIDEO_HISTORY_NOT_FOUND_MARKETING_TITLE: 'Ничего не найдено',
  VIDEO_HISTORY_NOT_FOUND_MARKETING_DESCRIPTION: 'Здесь будут отображены все действия, которые происходят с видео',
  INFO: 'Информация',
  POSITION: 'Должность',
  SUCCESS_UPDATE_DATA: 'Данные успешно обновлены',
  CHARTMETRIC_ID: 'Идентификатор артиста на Chartmetric.com',
  TOP_SONGWRITERS: 'Лучшие коллаборации',
  ARTIST_DATA_SOURCES: 'Источники данных об артисте',
  ARTIST_RANKING: 'Рейтинги артиста',
  CM_RANK_cm_artist_rank: 'Рейтинг артиста',
  CM_RANK_engagement_rank: 'Рейтинг вовлеченности',
  CM_RANK_sp_followers: 'Spotify подписчики',
  CM_RANK_sp_popularity: 'Spotify популярность',
  CM_RANK_sp_monthly_listeners: 'Spotify прослушивания за месяц',
  CM_RANK_youtube_monthly_video_views: 'YouTube просмотры за месяц',
  CM_RANK_tiktok_likes: 'TikTok лайки',
  CM_RANK_tiktok_followers: 'TikTok подписчики',
  CM_RANK_twitch_monthly_viewer_hours: 'Twitch стрим-часы за месяц',
  CM_RANK_shazam_count: 'Shazam',
  CM_RANK_GENRE: 'Жанр',
  CM_RANK_num_sp_editorial_playlists: 'Spotify плейлисты',
  CM_RANK_num_am_editorial_playlists: 'Apple Music плейлисты',
  CM_RANK_num_az_editorial_playlists: 'Amazon плейлисты',
  CM_RANK_num_de_editorial_playlists: 'Deezer плейлисты',
  TOP_TRACKS: 'Лучшие треки',
  PLAYLISTS: 'Плейлисты',
  PLAYLIST: 'Плейлист',
  TRACKS: 'Треки',
  TRACK: 'Трек',

  NOT_APPLICABLE: 'Н/Д',
  SUMMARY_STATISTICS: 'Общая статистика',
  MONTHLY_LISTENERS: 'Слушателей за месяц',
  POPULARITY: 'Популярность',
  PLAYLIST_REACH: 'Охват плейлистов',
  RANK_POSITIONS: 'Позиций',
  LISTENERS: 'Слушателей',
  PERCENT: 'Процент',
  ABSOLUTE_VALUES: 'Абсолютные значения',
  SET_CHARTMETRIC_ID: 'Связать артиста с Chartmetric',
  PARTNER_INTEGRATION: 'Интеграция',

  NOT_FOUND_SELECT_VIDEO_WITH_ACTIVE_CONTRACT: 'Отсутствуют видео без активного договора',
  ADS: 'Реклама',
  SUBSCRIPTION: 'YouTube Premium',
  TOTAL: 'Итого',
  SUBSCRIBE_TO_CHARTMETRIC_NEWSLETTER: 'Подписаться на еженедельную рассылку',
  UNSUBSCRIBE_FROM_CHARTMETRIC_NEWSLETTER: 'Отписаться от еженедельной рассылки',
  LINK_WITH_ARTIST: 'Связать с артистом',
  SUCCESS_LINKED_WITH_ARTIST: 'Связь с артистом успешно обновлена',
  ADD_CONTRACT: 'Добавить договор',
  CONTRACTUAL_DOCUMENTS: 'Загруженные документы',
  ATTACH_DOCUMENT: 'Прикрепите документ',
  CM_SCORE: 'Очки популярности',
  LOAD_MORE: 'Загрузить еще',
  PLAYLISTS_INFO: 'Информация о плейлистах',
  SHOW: 'Показать',
  NOT_FOUND_PLAYLISTS_INFO: 'Нет информации о плейлистах артиста',
  BACKGROUND_PROCESSES: 'Фоновые процессы',
  CONTRACTOR_OWNER: 'Владелец контрагента',
  MEDIA_CUBE_PAY_PARTNER_MODAL_TITLE: 'mcpay.io - надежный финансовый партнер',
  MC_PAY_ERROR_CANT_GET_OAUTH_USER_INFORMATION:
    'Не удалось получить данные, требующиеся для перехода в mcpay.io. Попробуйте повторить позже.',
  CASH_OUT: 'Вывести деньги',
  SUCCESS: 'Успешно',
  DOWNLOAD_ACCOUNTS_BALANCE_REPORT: 'Сформировать отчёт о балансах',
  SUCCESS_START_GENERATE_BALANCE_REPORT:
    'Отчёт начал генерироваться. На эл.почту будет отправлено письмо о заврешении генерации.',
  MC_PAY_FILTER: 'Интеграция с mcpay',
  MC_PAY: 'mcpay.io',
  FOR_MC_PAY: 'Есть интеграция с mcpay.io',
  NOT_FOR_MC_PAY: 'Нет интеграции с mcpay.io',
  MC_PAY_STATUS: 'Статус в mcpay',
  MC_PAY_STATUS_WAITING_SEND_TO_MC_PAY: 'Ожидает отправки',
  MC_PAY_STATUS_SUCCESS: 'Успешно обработано',
  MC_PAY_STATUS_ERROR: 'Обработано с ошибкой',
  MC_PAY_REGISTER: 'Начать пользоваться mcpay.io',
  MC_PAY_DESCRIPTION: 'Надежная платежная система mcpay.io',
  MC_PAY_DESCRIPTION_LIST_ITEM_ONE: 'Моментальный вывод денег',
  MC_PAY_DESCRIPTION_LIST_ITEM_SECOND: 'Доступ ко всей истории транзакций',
  MC_PAY_DESCRIPTION_LIST_ITEM_THIRD:
    'Вывод средств 12 различными способами, включая банковский расчетный счет и телефонный номер',
  RIGHTS: 'Права',
  ACTIVE_RIGHTS: 'Активные права',
  EXPIRED_RIGHTS: 'Истекшие права',
  DOESNT_HAVE_EXPIRED_DATE_RIGHTS: 'Не имеющие даты истечения права',
  SEARCH: 'Поиск',

  MC_PAY_WITHDRAWAL_REQUEST: 'Подтверждение вывода в MC PAY',
  CONFIRM_MC_PAY_OPERATIONS_CONFIRMATION_MESSAGE: 'Вы уверены, что хотите подтвердить отправку операций в MC PAY?',
  SUCCESS_CONFIRM_MC_PAY_OPERATIONS: 'Операции в ближайшее время будут отправлены в MC PAY',
  WITHDRAWAL_TO_MC_PAY_NO_OWNER_USER_ATTENTION: 'Извините, взаимодействие с mcpay.io доступно только управляющему',
  MC_PAY_INTEGRATION_TEMP_UNAVAILABLE:
    'Ведутся технические работы. Интеграция с mcpay.io временно недоступна, попробуйте позже',
  MC_PAY_AUTH_STATES: 'MC Pay auth-статусы',
  MC_PAY_AUTH_STATE: 'Состояние авторизации пользователя',
  MC_PAY_INTEGRATION_ENABLE: 'Интеграция с mcpay.io',
  INITIAL_CONTRACT_STATE: 'Первоначальное',
  CURRENT_CONTRACT_STATE: 'Текущее',
  CONTRACT_STATE: 'Состояние договора',

  PAYMENT_REQUEST_DESTINATION: 'Счет поступления',
  MC_PAY_DESCRIPTION_LIST_ITEM_FOURTH: 'Может взиматься комиссия провайдера',
  INPUT_PLACEHOLDER_PAYMENT_REQUEST_DESTINATION: 'Выберите счет, куда поступят деньги',
  SUCCESS_CONFIRM_PAYMENT_REQUEST: 'Запрос на выплату успешно подтвержден',
  SUCCESS_REJECT_PAYMENT_REQUEST: 'Запрос на выплату успешно отклонен',
  ['PAYMENT_REQUEST_DESTINATION_' + PaymentRequestDestination.BY_REQUISITES]: 'По реквизитам',
  ['PAYMENT_REQUEST_DESTINATION_' + PaymentRequestDestination.TO_MCPAY]: 'В mcpay.io',
  MC_PAY_ALREADY_REGISTER: 'Успешно авторизованы',
  WITHDRAWAL_SUM_WITHOUT_FEE: 'Поступит на ваш счет',
  SP_TAX_INFO: 'Комиссия за перевод',
  MC_PAY_PAYMENT_REQUEST: 'Запросить выплату в mcpay.io',
  GENERATE_DETAILED_EXCEL_REPORT_MODAL_TITLE: 'Сгенерировать детализированный отчет',
  DETAILED_EXCEL_REPORT: 'Детализированный отчет',
  GENERATE_DETAILED_EXCEL_REPORT_SUCCESS: 'Генерация отчета займет время. Вы получите email-уведомление о завершении',
  ADD_DATE: 'Дата добавления',
  FETCH_CONTRACT_CHANNELS_STATE: 'Загрузить текущие каналы',
  CHANGE_ALL_RATE_PERCENTS: 'Указать ставку на все',
  CHANGE_ALL_RATE_PERCENTS_MODAL_TITLE: 'Укажите ставку, которая применится ко всем каналам',
  FETCH_CONTRACT_CHANNELS_STATE_CONFIRMATION:
    'Вы уверены, что хотите загрузить список каналов, актуальный на текущий момент?',

  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.INIT]: 'Инициализация запроса',
  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.PARSE_URL_STATE]: 'Получение полезной нагрузки из ссылки',
  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.FETCH_USER_FROM_DB]: 'Получение сущности пользователя',
  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.VALIDATE_PASSPHRASE_STATE]: 'Проверка кодовой фразы пользователя',
  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.INIT_MC_PAY_USER_CREDENTIALS]: 'Инициализация credentials для MC Pay',
  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.AUTHORIZE_USER_ON_MC_PAY]: 'Авторизация пользователя в MC Pay',
  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.FETCH_MC_PAY_USER_INFORMATION]:
    'Получение информации о пользователе MC Pay',
  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.CREATE_INIT_BALANCE_TRANSACTION]:
    'Создание транзакции инициализации баланса',
  ['MC_PAY_AUTH_STATE_' + McPayAuthStateStatus.SUCCESS]: 'Успешно',

  ['MC_PAY_TRANSACTION_STATE_' + McPayTransactionStateType.INIT]: 'Инициализация транзакции',
  ['MC_PAY_TRANSACTION_STATE_' + McPayTransactionStateType.VALIDATION_FAILED]: 'Ошибка валидации',
  ['MC_PAY_TRANSACTION_STATE_' + McPayTransactionStateType.CALCULATE_INITIAL_BALANCE_TRANSACTION_SUM]:
    'Расчет суммы транзакции',
  ['MC_PAY_TRANSACTION_STATE_' + McPayTransactionStateType.CHECK_TRANSACTION_SUM_FAILED]: 'Проверка посчитанной суммы',
  ['MC_PAY_TRANSACTION_STATE_' + McPayTransactionStateType.CREATE_MC_PAY_TRANSACTION_REQUEST]:
    'Инициализация запроса на создание транзакции в Mc Pay',
  ['MC_PAY_TRANSACTION_STATE_' + McPayTransactionStateType.CREATE_INTERNAL_WITHDRAWAL_TO_MC_PAY_TRANSACTION]:
    'Создание внутренней транзакции списания',
  ['MC_PAY_TRANSACTION_STATE_' + McPayTransactionStateType.SUCCESS]: 'Успешно',

  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_CHANGE_VIDEOS]: 'Изменить видео',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_ADD_VIDEOS]: 'Добавить видео',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_DELETE_VIDEOS]: 'Удалить видео',

  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.is_change_youtube_channels]: 'Изменить каналы',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.is_add_youtube_channels]: 'Добавить каналы',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.is_delete_youtube_channels]: 'Удалить каналы',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_CHANGE_VIDEO_RATE_PERCENT]: 'Изменить процентную ставку',

  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_CHANGE_FEEDS]: 'Изменить площадки',
  ['SUP_AGREEMENTS_CHANGES_' + AvailableContractChange.IS_CHANGE_EXPIRES_AT]: 'Изменить дату истечения договора',

  ['ADS_REQUEST_STATUS_' + AdsStatus.IN_WORK]: 'В работе',
  ['ADS_REQUEST_STATUS_' + AdsStatus.REJECTED]: 'Отклонено',
  ['ADS_REQUEST_STATUS_' + AdsStatus.DONE]: 'Подтверждено',

  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.WISH]: 'Пожелание',
  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.PROBLEM]: 'Проблема',
  ['SUPPORT_TICKET_TYPE_' + SupportTicketType.QUESTION]: 'Вопрос',

  ['SUPPORT_TICKET_STATUS_' + SupportTicketStatus.OPEN]: 'Открыто',
  ['SUPPORT_TICKET_STATUS_' + SupportTicketStatus.CLOSED]: 'Закрыто',

  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.IN_WORK]: 'В работе',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.COMPLETE]: 'Завершено',
  ['PAYMENT_REQUEST_STATUS_' + PaymentRequestStatus.REJECT]: 'Отклонено',
  ['CONTRACT_STATUS_' + ContractStatus.ACTIVE]: 'Активный',
  ['CONTRACT_STATUS_' + ContractStatus.EXPIRED]: 'Истек',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.IN_WORK]: 'В работе',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.REJECTED]: 'Отклонено',
  ['FILTER_REQUEST_STATUS_' + FilterRequestStatus.APPROVED]: 'Подтверждено',
  ['TRANSACTION_STATUS_' + TransactionStatus.CONFIRMED]: 'Подтверждено',
  ['TRANSACTION_STATUS_' + TransactionStatus.REJECTED]: 'Отклонено',
  ['TRANSACTION_STATUS_' + TransactionStatus.WAITING_CONFIRM]: 'Ожидает',
  ['TRANSACTION_STATUS_' + TransactionStatus.CANCELED]: 'Отменено',

  ['ARTICLE_STATUS_' + ArticleStatus.DRAFT]: 'Черновик',
  ['ARTICLE_STATUS_' + ArticleStatus.PUBLISHED]: 'Опубликовано',

  ['VIDEO_STATUS_' + VideoStatus.DRAFT]: 'Черновик',
  ['VIDEO_STATUS_' + VideoStatus.WAITING_PREPARING]: 'Ожидает',
  ['VIDEO_STATUS_' + VideoStatus.PREPARING]: 'Подготавливается',
  ['VIDEO_STATUS_' + VideoStatus.PREPARING_ERROR]: 'Не удалось подготовить файл',
  ['VIDEO_STATUS_' + VideoStatus.READY_TO_PUBLISH]: 'Готово к публикации',
  ['VIDEO_STATUS_' + VideoStatus.PUBLISHED]: 'Опубликовано на площадках',

  ['VIDEO_ACTION_' + VideoActionType.CREATE]: 'Видео создано',
  ['VIDEO_ACTION_' + VideoActionType.DELETE]: 'Видео удалено',
  ['VIDEO_ACTION_' + VideoActionType.UPDATE]: 'Информация о видео обновлена',
  ['VIDEO_ACTION_' + VideoActionType.SEND_TO_PUBLISH]: 'Видео отправлено на публикацию',
  ['VIDEO_ACTION_' + VideoActionType.SYSTEM_CREATE_WATERMARKED_FILES]: 'Сгенерированы файлы с водяными знаками',
  ['VIDEO_ACTION_' + VideoActionType.SYSTEM_CREATE_SUP_AGREEMENT]: 'Создано дополнительное соглашение',
  ['VIDEO_ACTION_' + VideoActionType.ADMIN_UPDATE_WATERMARKED_FILES]: 'Администратор обновил файлы с водяными знаками',
  ['VIDEO_ACTION_' + VideoActionType.ADMIN_CONFIRM_PUBLISH]: 'Администратор подтвердил публикацию',
  ['VIDEO_ACTION_' + VideoActionType.ADMIN_REJECT_PUBLISH]: 'Администратор отклонил публикацию',
  ['VIDEO_ACTION_' + VideoActionType.SYSTEM_FAULT_WHEN_CREATE_WATERMARKED_FILES]:
    'Во время генерации файлов с водяными знаками произошла ошибка',

  ['CONTRACTOR_TYPE_' + ContractorType.LEGAL]: 'Юридическое лицо',
  ['CONTRACTOR_TYPE_' + ContractorType.ENTREPRENEUR]: 'Индивидуальный предприниматель',
  ['CONTRACTOR_TYPE_' + ContractorType.INDIVIDUAL]: 'Физическое лицо/Самозанятый',

  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.NEED_FILL_CONTRACTOR_REQUISITES]:
    'Требуется заполнение реквизитов',
  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.APPROVED]: 'Данные подтверждены',
  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.REJECTED]: 'Данные отклонены',
  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM]: 'Ожидание модерации',
  ['UPDATE_CONTRACTOR' + ContractorDataValidationStatus.NEED_FIX_REQUIREMENTS]: 'Треубется исправление замечений',

  ['NOTE_UPDATE_CONTRACTOR_' + ContractorDataValidationStatus.WAITING_ADMINISTRATOR_CONFIRM]:
    'Ваши данные отправлены на проверку и ожидают модерации администратором',
  ['NOTE_UPDATE_CONTRACTOR_' + ContractorDataValidationStatus.REJECTED]: 'Администратор отклонил изменения данных',
  ['NOTE_UPDATE_CONTRACTOR_' + ContractorDataValidationStatus.NEED_FIX_REQUIREMENTS]:
    'Требуется исправление замечаний, для более подробной информации перейдите <redirect>сюда</redirect>',

  ['FEED_' + Feed.YANDEX_ZEN]: 'Yandex Zen',
  ['FEED_' + Feed.YOUTUBE]: 'Youtube',
  ['FEED_' + Feed.BEELINE]: 'Beeline',
  ['FEED_' + Feed.RUTUBE]: 'Rutube',
  ['FEED_' + Feed.MTS]: 'MTS',
  ['FEED_' + Feed.OK]: 'Одноклассники',
  ['FEED_' + Feed.VK]: 'VK',
  ['FEED_' + Feed.TVIGLE]: 'Tvigle',
  ['FEED_' + Feed.MEGAFON]: 'Megafon',

  ['CONTRACT_TYPE_' + ContractType.YOUTUBE_PARTNER]: 'Youtube партнёр',
  ['CONTRACT_TYPE_' + ContractType.PUBLISH_VIDEO]: 'Публикация видео',

  ['REPORT_FREQUENCY_' + ReportFrequency.MONTHLY]: 'Ежемесячно',
  ['REPORT_FREQUENCY_' + ReportFrequency.QUARTERLY]: 'Ежеквартально',

  ['YOUTUBE_REPORT_TYPE_' + FinancialReportType.YOUTUBE_VIDEO_SUMMARY]: 'Доход от рекламы → Сводный отчет о видео',
  ['YOUTUBE_REPORT_TYPE_' + FinancialReportType.YOUTUBE_VIDEO_AGGREGATION]: 'Видео → Отчёт о видео',
  ['YOUTUBE_REPORT_TYPE_' + FinancialReportType.YOUTUBE_RED_LABEL_RAWDATA_VIDEO]: 'Доход от подписки → Отчет о видео',
  YANDEX_ZEN_REPORT: 'Доход от Яндекс Дзен',
  REPORT_SOURCE: 'Отчет по доходам платформы',
  JURISDICTION: 'Гражданство/Страна регистрации контрагента',
  JURISDICTION_LEGAL: 'Страна регистрации контрагента',
  JURISDICTION_ENTREPRENEUR: 'Страна регистрации контрагента',
  JURISDICTION_INDIVIDUAL: 'Гражданство',
  REPORT_SOURCE_PLATFORM: 'Платформа',
  ['FINANCIAL_REPORT_SOURCE_' + FinancialReportSource.YOUTUBE]: 'Youtube',
  ['FINANCIAL_REPORT_SOURCE_' + FinancialReportSource.YANDEX_ZEN]: 'Yandex Zen',

  ['CREATE_TRANSACTION_' + TransactionAction.CREATE_WITHDRAWAL]: 'Создать транзакцию вывода средств',
  ['CREATE_TRANSACTION_' + TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE]:
    'Создать транзакцию на возврат налогов в Youtube',
  ['CREATE_TRANSACTION_' + TransactionAction.CREATE_TO_OUR_ORGANIZATION]:
    'Создать транзакцию списания в нашу орагнизацию',

  ['CREATE_TRANSACTION_DESCRIPTION_' + TransactionAction.CREATE_WITHDRAWAL]:
    'Деньги с внутреннего счета выбранного контрагента будут переведены на внешний счет контрагента',
  ['CREATE_TRANSACTION_DESCRIPTION_' + TransactionAction.CREATE_YOUTUBE_TAX_TO_YOUTUBE]:
    'Деньги с внутреннего счета выбранного контрагента будут переведены на внутренний счет контрагента YouTube',
  ['CREATE_TRANSACTION_DESCRIPTION_' + TransactionAction.CREATE_TO_OUR_ORGANIZATION]:
    'Деньги с внутреннего счета выбранного контрагента будут переведены на внутренний счет контрагента ООО «Видео Контент» либо Video Content, Inc',

  ['OPERATION_TYPE_' + TransactionOperationType.INNER_TRANSFER]: 'Внутренний перевод',
  ['OPERATION_TYPE_' + TransactionOperationType.WITHDRAWAL]: 'Вывод средств',
  ['OPERATION_TYPE_' + TransactionOperationType.ADJUSTMENT]: 'Корректировка',
  ['OPERATION_TYPE_' + TransactionOperationType.RETURN_YOUTUBE_TAX]: 'Возврат налогов Youtube',
  ['OPERATION_TYPE_' + TransactionOperationType.PAYMENT_FROM_PLATFORM]: 'Выплаты с площадок',
  ['OPERATION_TYPE_' + TransactionOperationType.PAYMENT_FOR_SERVICE]: 'Выплаты со StarPro',
  ['OPERATION_TYPE_' + TransactionOperationType.WITHDRAWAL_TO_MCPAY]: 'Вывод денег в MCPay',
  ['OPERATION_TYPE_' + TransactionOperationType.WITHDRAWAL_TO_CACHE]: 'Вывод денег на внутренний счет',

  [ReportsProcessingStatus.CREATED]: 'Создан',
  [ReportsProcessingStatus.IN_WORK]: 'В обработке',
  [ReportsProcessingStatus.ERROR]: 'Ошибка',
  [ReportsProcessingStatus.PROCESSED]: 'Обработан',

  [DateFormat.DD_MM_YYYY]: 'ДД.ММ.ГГГГ',

  ['REPORT_PERIOD_STATUS_' + ReportPeriodStatus.OPEN]: 'Открыт',
  ['REPORT_PERIOD_STATUS_' + ReportPeriodStatus.CLOSED]: 'Закрыт',
  ['REPORT_PERIOD_STATUS_' + ReportPeriodStatus.OPENING]: 'Открывается',
  ['REPORT_PERIOD_STATUS_' + ReportPeriodStatus.CLOSING]: 'Закрывается',

  [`ACCOUNT_TYPE_${FinancialAccountType.INNER}_${Currency.USD}`]: 'Внутренний долларовый счёт',
  [`ACCOUNT_TYPE_${FinancialAccountType.INNER}_${Currency.RUB}`]: 'Внутренний рублёвый счёт',
  [`ACCOUNT_TYPE_${FinancialAccountType.CACHE}_${Currency.USD}`]: 'Выведенные средства в долларах',
  [`ACCOUNT_TYPE_${FinancialAccountType.CACHE}_${Currency.RUB}`]: 'Выведенные средства в рублях',
  [`ACCOUNT_TYPE_${FinancialAccountType.MC_PAY}_${Currency.USD}`]: 'Выведенные средства в mcpay.io',

  [`CONTRACTOR_DOC_TYPE_` + ContractorDocumentType.CONTRACT]: 'Договор',
  [`CONTRACTOR_DOC_TYPE_` + ContractorDocumentType.SUP_AGREEMENT]: 'Доп.Соглашение',
  [`CONTRACTOR_DOC_TYPE_` + ContractorDocumentType.FINANCIAL_DETAILS_REPORT]: 'Финансовый отчёт',

  SHORT_REFERRAL: "Реф.",
  IS_REFERRAL_LINKED_OPERATION: "Данная операция связана с рефераллом",
  IS_REFERRAL_PAYMENT_OPERATION: 'Выплата рефереру',
  WITH_REFERRAL: 'Только реферерам',
  WITHOUT_REFERRAL: 'Только авторам',
} as const;
