import {Privileges} from '../app/api/response-contracts/auth-response-contracts';
import {AdminRoutes, CommonRoutes, Routes} from './routes';
import {ICONS, ImageKey} from '../app/images/images';
import {DashboardAdminPage} from '../app/pages/admin/dashboard/dashboard-admin-page';
import React from 'react';
import {DashboardPage} from '../app/pages/dashboard/dashboard-page';
import {YoutubeVideosPage} from '../app/pages/youtube-video/videos-page/youtube-videos-page';
import {VideosPage} from '../app/pages/videos/videos-page';
import {VideoPage} from '../app/pages/videos/video-page/video-page';
import {UploadVideoPage} from '../app/pages/videos/upload-and-create/upload-video-page';
import {EditVideoPage} from '../app/pages/videos/edit-video-page';
import {YoutubeChannelsPage} from '../app/pages/youtube-channel/channels-page/youtube-channels-page';
import {YoutubeChannelPage} from '../app/pages/youtube-channel/channel-page/youtube-channel-page';
import {UsersPage} from '../app/pages/admin/user-management/users-page';
import {UserPage} from '../app/pages/admin/user-management/user-page';
import {ContractorsPage} from '../app/pages/admin/contractors/contractors-page/contractors-page';
import {ContractorPage} from '../app/pages/admin/contractors/contractor-page/contractor-page';
import {LocalizationKey} from '../app/hooks/use-intl';
import {GenresPage} from '../app/pages/admin/gengres/genres-page';
import {CountriesPage} from '../app/pages/admin/countries/countries-page';
import {ArtistsPage} from '../app/pages/artists/artists-page';
import {MailingsPage} from '../app/pages/admin/mailings/mailings-page';
import {MailingPage} from '../app/pages/admin/mailings/mailing-page';
import CreateOrEditMailingPage from '../app/pages/admin/mailings/create-or-edit-mailing-page';
import {InstructionsPage} from '../app/pages/admin/instructions/instructions-page';
import {UsersActionsPage} from '../app/pages/admin/user-actions/users-actions-page';
import {TicketsPages} from '../app/pages/support/tickets-pages';
import {TicketCardPage} from '../app/pages/support/ticket-card-page';
import {KnowledgeBasePage} from '../app/pages/admin/knowledge-base/knowledge-base-page';
import {ArtistPage} from '../app/pages/artists/artist-page';
import {YoutubeIntegrationPage} from '../app/pages/admin/youtube-integration/youtube-integration-page';
import {FilesPage} from '../app/pages/files/files-page';
import {FinancialAccountPage} from '../app/pages/admin/financial-accounts/financial-account/financial-account-page';
import {FinancialOperations} from '../app/pages/admin/transactions/financial-operations';
import {
  CreateEditContractorPage
} from '../app/pages/admin/contractors/contractor-page/edit/create-edit-contractor-page';
import {Contractor} from '../app/api/contractor-api/Contractor';
import {MyOrganizationPage} from '../app/pages/my-organization/my-organization-page';
import {ContractsPage} from '../app/pages/admin/contracts/contracts-page/contracts-page';
import {ContractPage} from '../app/pages/admin/contracts/contract-page/contract-page';
import {
  CreateContractPage
} from '../app/pages/admin/contracts/contract-page/create-edit-contract-page/create-contract-page';
import {
  EditContractPage
} from '../app/pages/admin/contracts/contract-page/create-edit-contract-page/edit-contract-page';
import {CreateEditSupAgreementPage} from '../app/pages/admin/contracts/sup-agreements/create-edit-sup-agreement-page';
import {SupAgreementPage} from '../app/pages/admin/contracts/sup-agreements/sup-agreement-page';
import {ApplicationSettingsPage} from '../app/pages/admin/application-settings/application-settings-page';
import {DownloadsPage} from '../app/pages/downloads/downloads-page';
import {ContractorDocumentsPage} from '../app/pages/contractor-documents/contractor-documents-page';
import {FinancialOperationPage} from '../app/pages/admin/transactions/financial-operation-page';
import {TransactionPage} from '../app/pages/admin/transactions/transaction-page';
import {FinancialReportsPage} from '../app/pages/admin/financial-reports/financial-reports-page';
import {MyOrganizationEditPage} from '../app/pages/my-organization/my-organization-edit-page';
import {ReportingPeriodsPage} from '../app/pages/admin/reporting-periods-page/reporting-periods-page';
import {ProfilePage} from '../app/pages/profile/profile-page';
import {FeedsIntegrationPage} from '../app/pages/admin/feed/feeds-integration-page';
import {User, UserType} from '../app/api/DTOs/User';
import {BackgroundProcessesPage} from '../app/pages/admin/background-processes/background-processes-page';
import {McPayAuthStatesPage} from '../app/pages/admin/mc-pay-auth-states/mc-pay-auth-states-page';
import {McPayAuthStatePage} from '../app/pages/admin/mc-pay-auth-states/mc-pay-auth-state-page';
import {CommonRevenuesPage} from '../app/pages/revenues/common-revenues-page';
import {PaymentRequestsPage} from '../app/pages/admin/payment-requests/payment-requests-page';
import {PaymentRequestPage} from '../app/pages/admin/payment-requests/payment-request-page';
import {ConnectChannelRequestsPage} from "../app/pages/admin/connect-channel-requests/connect-channel-requests-page";
import {ConnectChannelRequestPage} from "../app/pages/admin/connect-channel-requests/connect-channel-request-page";

export type PageConfiguration = BaseAsidePageConfiguration | BaseNonAsidePageConfiguration;

export type PageConfigurations = {
  forceRedirectTo: string | null;
  redirectRouteFromStartRoute: string;
  pages: Array<PageConfiguration | GroupPagesConfiguration>;
};

type BreadcrumbConfiguration = {
  breadcrumbs?: {
    isDynamic?: boolean;
    title?: LocalizationKey;
  };
};

export type BasePageConfigurationFields = {
  to: string;
  exact?: boolean;
  available?: boolean;
  depends?: Array<BaseAsidePageConfiguration | BaseNonAsidePageConfiguration>;
  component?: React.FC<any> | any;
  render?: (...args: any[]) => JSX.Element;
} & BreadcrumbConfiguration;

export type GroupPagesConfiguration = {
  icon: ImageKey;
  available?: boolean;
  title: LocalizationKey;
  pages: Array<PageConfiguration>;
};

export type BaseAsidePageConfiguration = {
  showInAside: true;
  icon: ImageKey;
  title: LocalizationKey;
} & BasePageConfigurationFields;

export type BaseNonAsidePageConfiguration = {
  showInAside: false;
} & BasePageConfigurationFields;

function getRedirectRouteFromStartRoute(user: User) {
  if (user.type === UserType.ARTIST && user.y_channel_id) {
    return Routes.getYoutubeSpecifyChannelRoute(user.y_channel_id);
  }

  if (user.type === UserType.USER) {
    return Routes.getDashboardRoute();
  }

  if (user.type === UserType.ADMIN) {
    return AdminRoutes.getDashboardRoute();
  }

  throw new Error('For generate start redirect route need valid user type');
}

export function buildAvailablePages(
  isAdmin: boolean,
  privileges: Privileges,
  contractor: Contractor | null,
  user: User,
): PageConfigurations {
  return {
    forceRedirectTo:
      user.type === UserType.ARTIST && user.y_channel_id
        ? Routes.getYoutubeSpecifyChannelRoute(user.y_channel_id)
        : null,
    redirectRouteFromStartRoute: getRedirectRouteFromStartRoute(user),
    pages: [
      {
        available: privileges.can_view_admin_dashboard,
        title: 'DASHBOARD',
        showInAside: true,
        to: AdminRoutes.getDashboardRoute(),
        icon: ICONS.LAYOUT,
        component: DashboardAdminPage,
      },
      {
        available: contractor != null && user.type !== UserType.ARTIST,
        showInAside: false,
        to: CommonRoutes.getMyOrganizationRoute(),
        component: MyOrganizationPage,
        breadcrumbs: {
          isDynamic: false,
          title: 'MY_ORGANIZATION',
        },
        depends: [
          {
            available: contractor != null,
            showInAside: false,
            to: CommonRoutes.getMyOrganizationEditRoute(),
            component: MyOrganizationEditPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: !privileges.can_view_admin_dashboard,
        showInAside: true,
        title: 'DASHBOARD',
        to: Routes.getDashboardRoute(),
        icon: ICONS.LAYOUT,
        component: DashboardPage,
      },
      {
        available: true,
        showInAside: true,
        title: 'YOUTUBE_CHANNELS',
        exact: true,
        to: Routes.getYoutubeChannelsRoute(),
        icon: ICONS.YOUTUBE,
        component: YoutubeChannelsPage,
        depends: [
          {
            available: true,
            showInAside: false,
            exact: true,
            to: Routes.getYoutubeSpecifyChannelRoute(':channelId'),
            component: YoutubeChannelPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: true,
        showInAside: true,
        title: 'YOUTUBE_ALL_VIDEOS',
        to: Routes.getYoutubeVideosRoute(),
        icon: ICONS.MEDIA,
        component: YoutubeVideosPage,
      },
      {
        showInAside: true,
        available: true,
        title: 'VIDEOS',
        to: Routes.getVideosRoute(),
        icon: ICONS.MEDIA,
        component: VideosPage,
        depends: [
          {
            available: true,
            showInAside: false,
            to: Routes.getUploadVideoRoute(),
            render: () => <UploadVideoPage openPagePayload={{uploadType: 'CREATE'}}/>,
            breadcrumbs: {
              isDynamic: false,
              title: 'UPLOAD_VIDEO',
            },
          },
          {
            available: true,
            showInAside: false,
            to: Routes.getEditUploadVideoRoute(':id'),
            component: EditVideoPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
          {
            available: true,
            showInAside: false,
            to: Routes.getSpecifyVideosRoute(':id'),
            component: VideoPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: privileges.can_view_my_contractor_documents,
        showInAside: true,
        title: 'MY_DOCUMENTS',
        to: CommonRoutes.getContractorDocumentsRoute(),
        icon: ICONS.CONTRACTS,
        component: ContractorDocumentsPage,
      },
      {
        available: privileges.can_view_users_list,
        showInAside: true,
        title: 'USER_MANAGEMENT',
        to: CommonRoutes.getUsersManagementPage(),
        icon: ICONS.USERS,
        component: UsersPage,
        depends: [
          {
            available: true,
            showInAside: false,
            to: CommonRoutes.getSpecifyUserManagementRoute(':id'),
            component: UserPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: privileges.can_manage_contractors,
        showInAside: true,
        title: 'CONTRACTORS',
        to: AdminRoutes.getContractorsRoute(),
        icon: ICONS.CONTRACTORS,
        component: ContractorsPage,
        depends: [
          {
            available: privileges.can_manage_contractors,
            showInAside: false,
            to: AdminRoutes.getEditContractorRoute(':id'),
            component: CreateEditContractorPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
          {
            available: privileges.can_manage_contractors,
            showInAside: false,
            to: AdminRoutes.getCreateContractorRoute(),
            component: CreateEditContractorPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
          {
            available: privileges.can_manage_contractors,
            showInAside: false,
            to: AdminRoutes.getSpecifyContractorRoute(':id'),
            component: ContractorPage,
            breadcrumbs: {
              isDynamic: true,
            },
            depends: [
              {
                showInAside: false,
                available: true,
                to: AdminRoutes.getSpecifyFinancialAccountsRoute(':contractorId', ':accountId'),
                component: FinancialAccountPage,
                breadcrumbs: {
                  isDynamic: true,
                  title: 'FINANCIAL_ACCOUNTS',
                },
              },
            ],
          },
        ],
      },
      {
        available: privileges.can_manage_contracts,
        showInAside: true,
        title: 'CONTRACTS',
        to: AdminRoutes.getContractsRoute(),
        icon: ICONS.CONTRACTS,
        component: ContractsPage,
        depends: [
          {
            exact: true,
            available: privileges.can_manage_contracts,
            showInAside: false,
            to: AdminRoutes.getCreateContractRoute(),
            component: CreateContractPage,
            breadcrumbs: {
              title: 'CREATE',
            },
          },
          {
            exact: true,
            available: privileges.can_manage_contracts,
            showInAside: false,
            to: AdminRoutes.getEditContractRoute(':id'),
            component: EditContractPage,
            breadcrumbs: {
              title: 'EDIT',
            },
          },
          {
            exact: true,
            available: privileges.can_manage_contracts,
            showInAside: false,
            to: AdminRoutes.getSpecifyContractRoute(':id'),
            component: ContractPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
          {
            exact: true,
            available: privileges.can_manage_contractors,
            showInAside: false,
            to: AdminRoutes.getCreateSupAgreementRoute(':contractId'),
            component: CreateEditSupAgreementPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
          {
            exact: true,
            available: privileges.can_manage_contractors,
            showInAside: false,
            to: AdminRoutes.getEditSupAgreementRoute(':contractId', ':supAgreementId'),
            component: CreateEditSupAgreementPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
          {
            exact: true,
            available: privileges.can_manage_contractors,
            showInAside: false,
            to: AdminRoutes.getSpecifySupAgreementRoute(':contractId', ':supAgreementId'),
            component: SupAgreementPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: privileges.can_manage_mailings,
        showInAside: true,
        title: 'MAILINGS',
        to: AdminRoutes.getMailingsRoute(),
        icon: ICONS.MAIL,
        component: MailingsPage,
        depends: [
          {
            available: privileges.can_manage_mailings,
            showInAside: false,
            to: AdminRoutes.getCreateMailingRoute(),
            render: () => <CreateOrEditMailingPage mode={'create'}/>,
          },
          {
            available: privileges.can_manage_mailings,
            showInAside: false,
            to: AdminRoutes.getEditMailingRoute(':id'),
            render: () => <CreateOrEditMailingPage mode={'edit'}/>,
            breadcrumbs: {
              isDynamic: true,
            },
          },
          {
            available: privileges.can_manage_mailings,
            showInAside: false,
            to: AdminRoutes.getSpecifyMailingRoute(':id'),
            component: MailingPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: privileges.can_manage_transactions,
        showInAside: true,
        title: 'FINANCIAL_OPERATIONS',
        to: AdminRoutes.getFinancialOperationsRoute(),
        icon: ICONS.DOLLAR,
        component: FinancialOperations,
        depends: [
          {
            available: privileges.can_manage_transactions,
            showInAside: false,
            to: AdminRoutes.getSpecifyTransactionRoute(':operationId', ':transactionId'),
            component: TransactionPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
          {
            available: privileges.can_manage_transactions,
            showInAside: false,
            to: AdminRoutes.getSpecifyFinancialOperations(':id'),
            component: FinancialOperationPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: privileges.can_view_my_accounts,
        showInAside: true,
        title: 'REVENUES',
        to: Routes.getRevenuesPageRoute(),
        icon: ICONS.DOLLAR,
        component: CommonRevenuesPage,
      },
      {
        available: true,
        showInAside: true,
        title: 'ARTISTS',
        to: Routes.getArtistsRoute(),
        icon: ICONS.SINGER,
        component: ArtistsPage,
        depends: [
          {
            available: privileges.can_manage_artists,
            showInAside: false,
            to: Routes.getSpecifyArtistsRoute(':id'),
            component: ArtistPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: isAdmin,
        showInAside: true,
        title: 'SUPPORT',
        to: AdminRoutes.getSupportRoute(),
        icon: ICONS.SUPPORT,
        render: () => <TicketsPages isAdmin/>,
        depends: [
          {
            available: privileges.can_manage_knowledge_base,
            showInAside: false,
            to: AdminRoutes.getSupportTicketRoute(':id'),
            render: () => <TicketCardPage isAdmin/>,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: !isAdmin,
        showInAside: false,
        to: Routes.getSupportRoute(),
        render: () => <TicketsPages isAdmin={false}/>,
        depends: [
          {
            available: !isAdmin,
            showInAside: false,
            to: Routes.getSupportTicketRoute(':id'),
            component: TicketCardPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        available: privileges.can_import_financial_reports,
        showInAside: true,
        title: 'YOUTUBE_FINANCIAL_REPORTS',
        icon: ICONS.UPLOADED_FILE,
        to: AdminRoutes.getFinancialReportsRoute(),
        component: FinancialReportsPage,
      },
      {
        showInAside: true,
        available: privileges.can_manage_payment_requests,
        title: 'PAYMENT_REQUESTS',
        icon: ICONS.CREDIT_CARD,
        to: AdminRoutes.getPaymentRequestsRoute(),
        component: PaymentRequestsPage,
        depends: [
          {
            showInAside: false,
            available: privileges.can_manage_payment_requests,
            to: AdminRoutes.getSpecifyPaymentRequestRoute(':id'),
            component: PaymentRequestPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        showInAside: true,
        available: privileges.can_manage_payment_requests,
        title: 'CONNECT_CHANNELS_REQUESTS',
        icon: ICONS.PLUS,
        to: AdminRoutes.getConnectChannelRequestsRoute(),
        component: ConnectChannelRequestsPage,
        depends: [
          {
            showInAside: false,
            available: privileges.can_manage_payment_requests,
            to: AdminRoutes.getSpecifyConnectChannelRequestRoute(':id'),
            component: ConnectChannelRequestPage,
            breadcrumbs: {
              isDynamic: true,
            },
          },
        ],
      },
      {
        showInAside: false,
        available: true,
        to: Routes.getProfileRoute(),
        component: ProfilePage,
        breadcrumbs: {
          isDynamic: false,
          title: 'PROFILE',
        },
      },
      {
        showInAside: true,
        available: !isAdmin,
        title: 'KNOWLEDGE_BASE',
        to: Routes.getKnowledgeBaseRoute(),
        icon: ICONS.GLOBE,
        component: KnowledgeBasePage,
      },
      {
        title: 'OTHER',
        available: isAdmin,
        icon: ICONS.DOTS,
        pages: [
          {
            showInAside: true,
            available: privileges.can_manage_report_periods,
            icon: ICONS.BOX,
            to: AdminRoutes.getReportsPeriodsRoute(),
            title: 'REPORTING_PERIODS',
            component: ReportingPeriodsPage,
          },
          {
            showInAside: true,
            available: true,
            title: 'KNOWLEDGE_BASE',
            to: Routes.getKnowledgeBaseRoute(),
            icon: ICONS.GLOBE,
            component: KnowledgeBasePage,
          },
          {
            available: privileges.can_manage_genres,
            showInAside: true,
            title: 'GENRES',
            to: AdminRoutes.getGenresRoute(),
            icon: ICONS.GENRE,
            component: GenresPage,
          },
          {
            available: true,
            showInAside: false,
            to: Routes.getDownloadsRoute(),
            component: DownloadsPage,
          },
          {
            available: privileges.can_manage_countries,
            showInAside: true,
            title: 'COUNTRIES',
            to: AdminRoutes.getCountriesRoute(),
            icon: ICONS.FLAG,
            component: CountriesPage,
          },
          {
            available: isAdmin,
            showInAside: true,
            title: 'INSTRUCTIONS',
            to: AdminRoutes.getInstructionsRoute(),
            icon: ICONS.CLIPBOARD,
            component: InstructionsPage,
          },
          {
            available: privileges.can_view_files,
            showInAside: true,
            title: 'FILES',
            to: AdminRoutes.getFilesRoute(),
            icon: ICONS.FILE,
            component: FilesPage,
          },
          {
            available: privileges.can_view_users_actions,
            showInAside: true,
            title: 'USERS_ACTIONS',
            to: AdminRoutes.getUsersActionsRoute(),
            icon: ICONS.CURSOR,
            component: UsersActionsPage,
          },
          {
            available: privileges.can_manage_google_oauth_token,
            showInAside: true,
            title: 'YOUTUBE_INTEGRATION',
            to: AdminRoutes.getYoutubeIntegrationRoute(),
            icon: ICONS.YOUTUBE,
            component: YoutubeIntegrationPage,
          },
          {
            available: isAdmin,
            title: 'PARTNER_INTEGRATION',
            showInAside: true,
            to: AdminRoutes.getPlatformIntegrationRoute(),
            icon: ICONS.RSS,
            component: FeedsIntegrationPage,
          },
          {
            available: privileges.can_manage_application_settings,
            title: 'APPLICATION_SETTINGS',
            showInAside: true,
            to: AdminRoutes.getApplicationSettingsRoute(),
            icon: ICONS.SETTINGS,
            component: ApplicationSettingsPage,
          },
          {
            available: isAdmin,
            title: 'MC_PAY_AUTH_STATES',
            showInAside: true,
            to: AdminRoutes.getMcPayStatesRoute(),
            icon: ICONS.TERMINAL,
            component: McPayAuthStatesPage,
            depends: [
              {
                available: isAdmin,
                showInAside: false,
                to: AdminRoutes.getMcPayAuthStateRoute(':id'),
                component: McPayAuthStatePage,
              },
            ],
          },
          {
            available: privileges.can_view_background_processes,
            title: 'BACKGROUND_PROCESSES',
            showInAside: true,
            to: AdminRoutes.getBackgroundProcessesRoute(),
            icon: ICONS.TERMINAL,
            component: BackgroundProcessesPage,
          },
        ],
      },
    ],
  };
}
